@tailwind base;
@tailwind components;
@tailwind utilities;

/* Reset and base styles */
body {
  font-family: 'Inter', sans-serif;
  background-color: #000000;
  color: #E0E0E0;
  margin: 0;
  padding: 0;
  min-height: 100vh;
  overflow-x: hidden;
  width: 100%;
}

/* Root and section controls */
#root {
  background-color: #000000;
  overflow-x: hidden;
  position: relative;
  width: 100%;
}

section {
  background-color: #000000;
  overflow-x: hidden;
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
}

/* Link styles */
a {
  color: #E0E0E0;
  text-decoration: none;
}

a:hover {
  color: #BB86FC;
}

/* Color overrides */
.bg-black {
  background-color: #000000 !important;
}

.text-white {
  color: #FFFFFF !important;
}

/* Container control */
.container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1rem;
  padding-right: 1rem;
  max-width: 100vw !important;
}

/* Mobile-specific fixes */
@media (max-width: 640px) {
  body, #root, section {
    overflow-x: hidden;
  }
  
  .container {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

/* Prevent horizontal scroll */
.overflow-hidden {
  overflow-x: clip;
}

/* Fix for any full-width sections */
.w-screen {
  position: relative;
  width: 100vw;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  overflow-x: hidden;
}

/* Override text color for form elements only */
input,
textarea,
select {
  color: #000000;
}





